import React from 'react';
import SEO from '../components/seo';
import ErrorContent from '../util/Errors/ErrorContent';

const ErrorPage = () => (
  <>
    <SEO title="Error" />
    <ErrorContent />
  </>
);

export default ErrorPage;
